<template>
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <!--begin::Subheader-->
    <div
      class="subheader py-2 py-lg-12 subheader-transparent"
      id="kt_subheader"
    >
      <div
        class="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
      >
        <!--begin::Info-->
        <div class="d-flex align-items-center flex-wrap mr-1">
          <!--begin::Heading-->
          <div class="d-flex flex-column">
            <!--begin::Title-->
            <h2 class="text-white font-weight-bold my-2 mr-5">
              材料超市
            </h2>
            <!--end::Title-->
            <!--begin::Breadcrumb-->
            <div class="d-flex align-items-center font-weight-bold my-2">
              <!--begin::Item-->
              <a href="#" class="opacity-75 hover-opacity-100 pr-3">
                <i class="flaticon2-shelter text-white icon-1x"></i>
              </a>
              <!--end::Item-->
              <!--begin::Item-->
              <select class="form-control" style="width: 200px;">
                <option>领德集团</option>
              </select>
              <!--end::Item-->
            </div>
            <!--end::Breadcrumb-->
          </div>
          <!--end::Heading-->
        </div>
        <!--end::Info-->
      </div>
    </div>
    <div class="d-flex flex-column-fluid">
      <div class="container">
        <div
          class="alert alert-custom alert-white alert-shadow gutter-b"
          role="alert"
        >
          <div class="alert-text">
            <div>
              <h3 class="card-label">
                物料筛选
              </h3>
            </div>
            <div class="mb-7 pt-3">
              <div class="row align-items-center">
                <div class="col-md-12">
                  覆铜板材:
                  <div class="nav nav-dark order-1 order-md-2">
                    <a
                      target="_blank"
                      class="nav-link pr-3 pl-0"
                      v-for="(item, index) in materialTypeList"
                      :key="index"
                      @click="serachClick(item, 1)"
                      ><span
                        class="label label-primary label-inline font-weight-lighter mr-2"
                        v-if="item.cssFlag"
                        >{{ item.name }}</span
                      >
                      <span
                        class="label label label-inline font-weight-lighter mr-2"
                        v-if="!item.cssFlag"
                        >{{ item.name }}</span
                      ></a
                    >
                  </div>
                </div>
                <div
                  class="col-md-12 pt-3"
                  v-if="suppList && suppList.length > 0"
                >
                  物料品牌：
                  <div class="nav nav-dark order-1 order-md-2">
                    <a
                      target="_blank"
                      class="nav-link pr-3 pl-0"
                      v-for="(item, index) in suppList"
                      :key="index"
                      @click="serachClick(item, 3)"
                      ><span
                        class="label label-primary label-inline font-weight-lighter mr-2"
                        v-if="item.cssFlag"
                        >{{ item.name }}</span
                      >
                      <span
                        class="label label label-inline font-weight-lighter mr-2"
                        v-if="!item.cssFlag"
                        >{{ item.name }}</span
                      ></a
                    >
                  </div>
                </div>
                <div
                  class="col-md-12 pt-3"
                  v-if="boardThicknessList && boardThicknessList.length > 0"
                >
                  板材厚度：
                  <div class="nav nav-dark order-1 order-md-2">
                    <a
                      target="_blank"
                      class="nav-link pr-3 pl-0"
                      v-for="(item, index) in boardThicknessList"
                      :key="index"
                      @click="serachClick(item, 2)"
                      ><span
                        class="label label-primary label-inline font-weight-lighter mr-2"
                        v-if="item.cssFlag"
                        >{{ item.name }}</span
                      >
                      <span
                        class="label label label-inline font-weight-lighter mr-2"
                        v-if="!item.cssFlag"
                        >{{ item.name }}</span
                      ></a
                    >
                  </div>
                </div>
              </div>
              <div class="row align-items-center">
                <div class="col-lg-9 col-xl-8 pt-3">
                  <div class="row align-items-center">
                    <div class="col-md-12 my-2 my-md-0">
                      <div class="input-icon">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="输入物料规格..."
                          v-model="specificationQuery"
                          v-on:change="searchSpecificationData"
                        />
                        <span
                          ><i class="flaticon2-search-1 text-muted"></i
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card card-custom">
          <div class="card-body">
            <!--begin: Search Form-->
            <!--begin::Search Form-->
            <div class="mb-7">
              <div class="row align-items-center">
                <div class="col-lg-12">
                  <div
                    class="row align-items-center border-bottom pt-4 pb-4"
                    v-for="(item, index) in materialList"
                    :key="index"
                  >
                    <div class="col-md-3">
                      {{ item.name }}
                    </div>
                    <div class="col-md-6">
                      {{ item.specification }}
                    </div>
                    <div class="col-md-3">库存:&emsp;{{ item.stocks }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";
export default {
  name: "materialList",
  components: {},
  computed: {
    testImage() {
      return process.env.BASE_URL + "media/test/xiang.jpg";
    }
  },
  created() {
    this.initData();
  },
  data() {
    return {
      mainFlag: true,
      list: [],
      materialList: [],
      materialType: "",
      materialTypeList: [],
      supp: "",
      suppList: [],
      boardThickness: "",
      boardThicknessList: [],
      specificationQuery: ""
    };
  },
  methods: {
    toMain: function() {
      this.$router.push("/dashboard");
    },
    changeFlag: function() {
      alert("正在开发中");
      // this.mainFlag = !this.mainFlag;
      // if (this.mainFlag) {
      //   this.$router.push("/dashboard");
      // } else {
      //   this.$router.push("/dashboard/shopCat");
      // }
    },
    buy: function() {
      alert("正在开发中");
    },
    initData: function() {
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      this.$axios
        .fetchPost("contract/getMaterialList", 17)
        .then(data => {
          if (data && data.data) {
            this.list = data.data;
            this.initCraftList();
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    initCraftList: function() {
      this.materialTypeList = [];
      this.suppList = [];
      this.boardThicknessList = [];
      if (this.list && this.list.length > 0) {
        for (let i = 0; i < this.list.length; i++) {
          if (this.list[i] && this.list[i].materialType) {
            let flag = false;
            for (let j = 0; j < this.materialTypeList.length; j++) {
              if (this.materialTypeList[j].name === this.list[i].materialType) {
                flag = true;
                break;
              }
            }
            if (!flag) {
              let obj = {};
              obj.name = this.list[i].materialType;
              obj.cssFlag = false;
              this.materialTypeList.push(obj);
            }
          }
          /*          if (this.list[i] && this.list[i].boardThickness) {
            let flag = false;
            for (let j = 0; j < this.boardThicknessList.length; j++) {
              if (
                this.boardThicknessList[j].name === this.list[i].boardThickness
              ) {
                flag = true;
                break;
              }
            }
            if (!flag) {
              let obj = {};
              obj.name = this.list[i].boardThickness;
              obj.cssFlag = false;
              this.boardThicknessList.push(obj);
            }
          }
          if (this.list[i] && this.list[i].manufacturer) {
            let flag = false;
            for (let j = 0; j < this.suppList.length; j++) {
              if (this.suppList[j].name === this.list[i].manufacturer) {
                flag = true;
                break;
              }
            }
            if (!flag) {
              let obj = {};
              obj.name = this.list[i].manufacturer;
              obj.cssFlag = false;
              this.suppList.push(obj);
            }
          }*/
        }
      }
      this.materialList = this.list;
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    },
    serachClick: function(item, num) {
      switch (num) {
        case 1:
          for (let materialType of this.materialTypeList) {
            materialType.cssFlag = false;
          }
          this.materialType = item.name;
          this.supp = "";
          this.suppList = [];
          this.boardThickness = "";
          this.boardThicknessList = [];
          break;
        case 2:
          for (let boardThickness of this.boardThicknessList) {
            boardThickness.cssFlag = false;
          }
          this.boardThickness = item.name;
          this.suppList = [];
          break;
        case 3:
          for (let supp of this.suppList) {
            supp.cssFlag = false;
          }
          this.supp = item.name;
          this.boardThicknessList = [];
          break;
      }
      item.cssFlag = !item.cssFlag;
      this.searchList(num);
      this.searchSpecificationData();
    },
    searchList: function(num) {
      this.materialList = [];
      for (let i = 0; i < this.list.length; i++) {
        const material = this.list[i];
        if (material.manufacturer && (num === 1 || num === 2)) {
          let flag = false;
          for (let j = 0; j < this.suppList.length; j++) {
            if (this.suppList[j].name === material.manufacturer) {
              flag = true;
              break;
            }
          }
          if (this.returnFlag(flag, material, num)) {
            let obj = {};
            obj.name = material.manufacturer;
            if (obj.name === this.supp) {
              obj.cssFlag = true;
            } else {
              obj.cssFlag = false;
            }
            this.suppList.push(obj);
          }
        }
        if (material.boardThickness && (num === 1 || num === 3)) {
          let flag = false;
          for (let j = 0; j < this.boardThicknessList.length; j++) {
            if (this.boardThicknessList[j].name === material.boardThickness) {
              flag = true;
              break;
            }
          }
          if (this.returnFlag(flag, material, num)) {
            let obj = {};
            obj.name = material.boardThickness;
            if (obj.name === this.boardThickness) {
              obj.cssFlag = true;
            } else {
              obj.cssFlag = false;
            }
            this.boardThicknessList.push(obj);
          }
        }
        let flag = false;
        for (let j = 0; j < this.materialList.length; j++) {
          if (this.materialList[j] === material.recordId) {
            flag = true;
            break;
          }
        }
        if (this.returnFlag(flag, material, num)) {
          this.materialList.push(material);
        }
      }
    },
    returnFlag: function(flag, material, num) {
      let oneFlag = false;
      let twoFlag = false;
      let threeFlag = false;
      let fourFlag = false;

      if (!flag) {
        oneFlag = true;
      }
      if (this.materialType && this.materialType !== "") {
        if (material.materialType === this.materialType) {
          twoFlag = true;
        }
      }
      if (this.boardThickness && this.boardThickness !== "") {
        if (material.boardThickness === this.boardThickness) {
          threeFlag = true;
        }
      }
      if (this.supp && this.supp !== "") {
        if (material.manufacturer === this.supp) {
          fourFlag = true;
        }
      }
      let returnFlag = false;
      switch (num) {
        case 1:
          if (oneFlag && twoFlag) {
            returnFlag = true;
          }
          break;
        case 2:
          if (oneFlag && twoFlag && threeFlag) {
            returnFlag = true;
          }
          break;
        case 3:
          if (oneFlag && twoFlag && fourFlag) {
            returnFlag = true;
          }
          break;
      }
      return returnFlag;
    },
    searchSpecificationData: function() {
      if (this.specificationQuery && this.specificationQuery !== "") {
        if (!this.materialList || this.materialList.length === 0) {
          this.searchList(3);
        }
        let list = [];
        let queryList = this.specificationQuery.split(" ");
        let query1 = "";
        let query2 = "";
        let query3 = "";
        let query4 = "";
        let query5 = "";
        let query6 = "";
        if (queryList && queryList.length > 0) {
          for (let i = 0; i < queryList.length; i++) {
            let query = queryList[i];
            switch (i) {
              case 0:
                query1 = query;
                break;
              case 1:
                query2 = query;
                break;
              case 2:
                query3 = query;
                break;
              case 3:
                query4 = query;
                break;
              case 4:
                query5 = query;
                break;
              case 5:
                query6 = query;
                break;
            }
          }
        }
        this.materialList.filter(material => {
          let flag = false;
          if (query1 && query1 !== "") {
            if (material.specification.toLowerCase().indexOf(query1) !== -1) {
              flag = true;
            } else {
              flag = false;
            }
          }
          if (query2 && query2 !== "") {
            if (material.specification.toLowerCase().indexOf(query2) !== -1) {
              flag = true;
            } else {
              flag = false;
            }
          }
          if (query3 && query3 !== "") {
            if (material.specification.toLowerCase().indexOf(query3) !== -1) {
              flag = true;
            } else {
              flag = false;
            }
          }
          if (query4 && query4 !== "") {
            if (material.specification.toLowerCase().indexOf(query4) !== -1) {
              flag = true;
            } else {
              flag = false;
            }
          }
          if (query5 && query5 !== "") {
            if (material.specification.toLowerCase().indexOf(query5) !== -1) {
              flag = true;
            } else {
              flag = false;
            }
          }
          if (query6 && query6 !== "") {
            if (material.specification.toLowerCase().indexOf(query6) !== -1) {
              flag = true;
            } else {
              flag = false;
            }
          }
          if (flag) {
            list.push(material);
          }
        });
        this.materialList = list;
      }
    }
  }
};
</script>
